<!-- 查看详情 -->
<template>
  <div>
    <back />
    <div class="applyDetail" v-loading="loading">
      <h3 class="title">提现信息</h3>

      <!-- 支付宝 -->
      <div v-if="detail?.accountType == '1'">
        <el-row class="row">
          <el-col :span="10">
            <div class="col"><span class="txt">申请单号:</span>{{ detail?.applyNum }}</div>
            <div class="col"><span class="txt">真实姓名:</span>{{ detail?.accountName }}</div>
            <div class="col"><span class="txt">提现时间:</span>{{ detail?.createdTime }}</div>
            <div class="col"><span class="txt">状态:</span>{{ getStatusText(detail?.status) }}</div>

          </el-col>
          <el-col :span="8">
            <div class="col"><span class="txt">操作人:</span>{{ detail?.createdUserName }}</div>
            <div class="col"><span class="txt">收款账号:</span>{{ detail?.accountNum }}</div>
            <div class="col"><span class="txt">转账时间:</span>{{ detail?.receivedTime ? detail.receivedTime : '-' }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col"><span class="txt">账户类型:</span>{{ detail?.accountType == '1' ? '支付宝' : '银行卡' }}</div>
            <div class="col"><span class="txt">提现金额:</span><span
                v-if="detail?.applyAmount || detail?.applyAmount == 0">{{
      detail?.applyAmount + '元'
    }}</span></div>
            <div class="col"><span class="txt">审核时间:</span>{{ detail?.updatedTime ? detail.updatedTime : '-' }}</div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col class="col red" v-if="detail?.auditMsg"><span class="txt">不通过原因:</span>{{ detail?.auditMsg }}</el-col>
        </el-row>
      </div>

      <!-- 银行卡 -->
      <div v-if="detail?.accountType == '2'">
        <el-row class="row">
          <el-col :span="10">
            <div class="col"><span class="txt">申请单号:</span>{{ detail?.applyNum }}</div>
            <div class="col"><span class="txt">支行名称:</span>{{ detail?.opeBank }}</div>
            <div class="col"><span class="txt">提现金额:</span><span
                v-if="detail?.applyAmount || detail?.applyAmount == 0">{{
      detail?.applyAmount + '元'
    }}</span></div>
            <div class="col"><span class="txt">审核时间:</span>{{ detail?.updatedTime ? detail.updatedTime : '-' }}</div>
          </el-col>
          <el-col :span="8">
            <div class="col"><span class="txt">操作人:</span>{{ detail?.createdUserName }}</div>
            <div class="col"><span class="txt">真实姓名:</span>{{ detail?.accountName }}</div>
            <div class="col"><span class="txt">提现时间:</span>{{ detail?.createdTime }}</div>
            <div class="col"><span class="txt">状态:</span>{{ getStatusText(detail?.status) }}</div>
          </el-col>
          <el-col :span="6">
            <div class="col"><span class="txt">账户类型:</span>{{ detail?.accountType == '1' ? '支付宝' : '银行卡' }}</div>
            <div class="col"><span class="txt">收款账号:</span>{{ detail?.accountNum }}</div>
            <div class="col"><span class="txt">转账时间:</span>{{ detail?.receivedTime ? detail.receivedTime : '-' }}</div>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col class="col red" v-if="detail?.auditMsg"><span class="txt">不通过原因:</span>{{ detail?.auditMsg }}</el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      detail: {},
      id: '',
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getApplyDetail();
  },
  methods: {
    getApplyDetail() {
      this.loading = true;
      this.$axios.get(`${this.$api.getSupplierExtractApplyListDetail}/${this.id}`).then((res) => {
        if (res.data.code == 100) {
          this.detail = res.data.result;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    getStatusText(status) {
      switch (status) {
        case '0':
          return "待审核";
        case '1':
          return "已转账";
        case '2':
          return "审核失败";
        case '3':
          return "转账失败";
        default:
          return " ";
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.applyDetail {
  padding: 20px;

  .title {
    margin-bottom: 25px;
  }

  .row {
    color: #707070;
  }

  .col {
    margin-bottom: 25px;
  }

  .red {
    color: red;
  }

  .txt {
    margin-right: 8px;
  }

}
</style>
